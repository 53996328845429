body {
  margin: 0;
  font-family: "Kanit", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #1f1f1f;
  color: #e0e0e0; /* Text color for better readability */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Kanit", monospace;
}

.app {
  width: calc(100% - 17.5rem); /* Adjust width based on navbar width */
  margin-left: 17.5rem; /* Add margin to push content away from the navbar */
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden; /* Prevent horizontal scroll */
  overscroll-behavior: none;
  overflow-y: auto;
  padding-bottom: env(safe-area-inset-bottom);
}

.nav-bar {
  position: fixed; /* Fixes the navbar in place */
  top: 0;
  left: 0;
  width: 250px; /* Set a fixed width for the navbar */
  height: 100vh; /* Full height of the viewport */
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: space-between; /* Space out items: top content, buttons in the middle, and footer at the bottom */
  align-items: center; /* Center items horizontally */
  background-color: #151515;
  color: #e0e0e0;
  font-family: "Kanit";
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 4px;
  padding: 1rem;
  z-index: 100;
}

.nav-bar .left-content {
  display: flex;
  align-items: center; /* Vertically centers the image and text */
  padding-left: 1rem;
  text-align: center;
  color: #e0e0e0;
  line-height: 1.5rem;
}

.link-div {
  text-decoration: none; /* Remove underline from the link */
  display: block; /* Ensures the div takes full width */
  color: inherit; /* Inherit color from parent element */
}

.buttons {
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  align-items: center; /* Align buttons to the left */
  justify-content: center;
  text-transform: uppercase;
  gap: 2rem; /* Space between buttons */
  padding-left: 0rem;
  padding-bottom: 5rem;
}

.buttons button {
  background-color: transparent;
  text-transform: uppercase;
  font-family: "Kanit";
  color: #e0e0e0;
  border: 1px solid white;
  padding: 10px 20px;
  font-size: 1.1rem; /* Adjust font size as needed */
  cursor: pointer;
  transition: background-color 0.6s, box-shadow 0.6s ease;
  border-radius: 5px; /* Modern rounded button */
}

.buttons button:hover {
  background-color: #333;
  color: #e0e0e0;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow on hover */
}

.image {
  width: 50px;
  height: 50px;
  padding-right: 1.5rem;
}

.footer {
  background-color: #151515;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%; /* Ensure it takes full width */
  overflow: hidden;
  align-items: center;
  justify-content: center;
  position: relative;
  display: none;
}

.icons {
  height: 25px;
  width: auto;
  padding-top: 0.15rem;
}

.footer-links {
  display: inline-flex; /* Use inline-flex so the box only wraps around content */
  align-items: center; /* Vertically center items */
  padding: 0.1rem; /* Add padding inside the box */
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  justify-content: center;
}

.footer-nav-bar {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex; /* or static */
  width: 100%; /* Ensure it takes full width */
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.body {
  display: flex;
  flex-direction: column; /* Stack the sections vertically */
  width: 100vw; /* Full viewport width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.section {
  padding-left: 4rem;
  padding-right: 2rem;
  animation: fadeIn 0.5s;
  width: 100vw; /* Full viewport width for each section */
  /* min-height: 100vh; */
  line-height: 1.8;
  box-sizing: border-box;
}

h1,
h2 {
  margin-bottom: 1rem;
  color: #bb86fc; /* Modern color for headings */
}

p {
  margin-bottom: 1.5rem;
  color: #b0b0b0; /* Softer color for body text */
}

.social-links {
  display: flex;
  justify-content: space-around;
  margin-bottom: 2rem;
}

.social-links a {
  color: #bb86fc;
  font-weight: bold;
  text-decoration: none;
}

.social-links a:hover {
  text-decoration: underline;
}

button.subscribe-btn {
  background-color: #bb86fc;
  color: #1f1f1f;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

button.subscribe-btn:hover {
  background-color: #cf9fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.hero {
  position: relative; /* Keep it relative */
  height: 100vh;
  width: 100%; /* Ensure full width */
  overflow: hidden; /* Prevent overflow from content */
  margin: 0; /* Ensure there's no margin */
  padding: 0; /* Ensure there's no padding */
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
}

.hero-image {
  position: absolute; /* Position it within the hero */
  top: 0;
  left: 0;
  width: 100vw; /* Full width of the viewport */
  height: 100vh; /* Full height of the hero section */
  object-fit: cover; /* Cover the area without distortion */
  z-index: 1; /* Send it behind other content */
}

.hero-overlay {
  position: absolute; /* Position it over the video */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Cover the entire area */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 2; /* Above the video but below the text */
}

.hero-content {
  /* background-color: rgba(0, 0, 0, 0.5); */
  position: absolute; /* Absolute position to place it within the hero */
  bottom: 0; /* Position at the bottom */
  z-index: 2; /* Content is above the video */
  color: white; /* Text color */
  padding: 2rem;
  width: 65vw; /* Set width */
  border-radius: 15px;
  display: flex; /* Use flexbox */
  flex-direction: column; /* Align items in a column */
  justify-content: center; /* Center content vertically */
  align-items: flex-start; /* Align content to the left */
}

.hero-content h1,
.hero-content span {
  text-align: left; /* Ensure text is left-aligned */
  margin: 0; /* Remove any default margin */
}

.hero-content span {
  font-size: 350%;
}

.course {
  display: flex; /* Use flexbox */
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  width: 100%; /* Ensure it takes full width of the parent container */
  padding: 0; /* Remove any unnecessary padding */
  padding-top: 3rem;
  padding-bottom: 3rem;
  box-sizing: border-box; /* Ensure padding and border are included in the element’s total width */
  line-height: 1rem;
  background-color: #1515156e;
}

.course-header {
  line-height: 3rem;
  text-align: center;
}

.course-header p {
  font-size: 100%;
  /* text-transform: capitalize; */
}

.course-news-link {
  background-color: transparent;
  text-transform: uppercase;
  padding: 1rem;
  cursor: pointer;
  justify-content: center;
  color: #cf9fff;
  text-decoration: none;
  display: flex; /* Add this */
  align-items: center; /* Centers both text and image vertically */
  border: 1px solid #cf9fff;
  transition: background-color 0.6s, box-shadow 0.6s ease;
  border-radius: 2rem; /* Modern rounded button */
}

.course-news-link:hover {
  background-color: #cf9fff20;
  text-decoration: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.course-news-link-image {
  height: 25px;
  width: auto;
  padding-right: 0.5rem;
}

.course-news-link span {
  padding-right: 3rem;
  padding-left: 0.5rem;
}
.newsletter {
  display: flex; /* Use flexbox */
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  width: 100%; /* Ensure it takes full width of the parent container */
  padding: 0; /* Remove any unnecessary padding */
  padding-top: 3rem;
  padding-bottom: 3rem;
  box-sizing: border-box;
  line-height: 1rem;
  background-color: #1515156e;
  height: 40vh;
  position: relative;
}

.newsletter h2 {
  text-transform: uppercase;
  font-size: 400%;
  line-height: 1;
  text-align: center;
}
.newsletter p {
  text-transform: capitalize;
  font-size: 200%;
  padding-bottom: 3vh;
  margin-top: 0;
  line-height: 1;
  text-align: center;
}
.buttons button {
  background-color: transparent;
  text-transform: uppercase;
  font-family: "Kanit";
  color: #e0e0e0;
  border: 1px solid white;
  padding: 10px 20px;
  font-size: 1.1rem; /* Adjust font size as needed */
  cursor: pointer;
  transition: background-color 0.6s, box-shadow 0.6s ease;
  border-radius: 5px; /* Modern rounded button */
}

.buttons button:hover {
  background-color: #333;
  color: #e0e0e0;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow on hover */
}

.aboutme {
  position: relative; /* Keep it relative */
  width: 100%; /* Ensure it takes full width */
  overflow: hidden; /* Prevent overflow from content */
  margin: 0; /* Ensure there's no margin */
  padding: 0; /* Ensure there's no padding */
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  background-color: #1f1f1f;
  min-height: 65vh; /* Optional: ensures at least full viewport height */
}

.aboutme-image {
  position: absolute;
  top: 1vh;
  right: 12vw;
  width: 30vw;
  height: auto; /* Change height to auto */
  max-height: calc(100vh - 2vh); /* Prevent it from exceeding viewport height */
  object-fit: cover;
  z-index: 1;
}

.aboutme-image-overlay {
  position: absolute;
  top: 1vh;
  right: 12vw;
  width: 30vw;
  height: auto; /* Change height to auto */
  max-height: calc(100vh - 2vh); /* Prevent it from exceeding viewport height */
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.4);
}

.aboutme-content {
  /* background-color: rgba(0, 0, 0, 0.9); */
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 4rem;
  width: 60vw;
  height: auto; /* Change height to auto */
  margin: 0;
  position: relative; /* Change to relative to allow natural flow */
  padding-bottom: 2vh;
}

.aboutme-content h1 {
  font-size: 5rem;
  line-height: 1;
  margin-bottom: 0;
}

.aboutme-inline-text {
  display: flex; /* Use flexbox to align span and h2 on the same line */
  align-items: center; /* Vertically align them to the center */
}

.aboutme-inline-text span {
  font-size: 2rem;
  line-height: 1;
  padding-left: 0.3rem;
}

.aboutme-inline-text h2 {
  font-size: 1.5rem;
  line-height: 1;
  padding-left: 1rem;
}

.recent-posts {
  margin: 1rem 0;
}

.post {
  padding-left: 1vw;
  margin-bottom: 0rem;
}

.post h3 {
  padding-left: 1vw;
}

.youtube-post {
  position: relative;
  display: inline-block;
}

.youtube-thumbnail {
  width: 32.6vw; /* Adjust as needed */
}

.youtube-title {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0.36rem;
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent black */
  color: white;
  display: flex;
  align-items: flex-end;
  justify-content: left;
  font-size: 1.2rem;
  padding-left: 2rem;
  padding-bottom: 1.25rem;
  text-align: center;
  overflow: hidden;
}

.instagram-post {
  position: relative; /* Position relative for absolute positioning of content */
  width: 100%; /* Make full width */
  height: 300px; /* Set a fixed height for the post */
  border-radius: 8px;
  overflow: hidden;
  color: white; /* White text for better contrast */
  display: flex;
  align-items: flex-end; /* Align content to the bottom */
  padding: 10px; /* Padding for text */
}

.post-caption {
  background-color: rgba(
    0,
    0,
    0,
    0.6
  ); /* Semi-transparent background for caption */
  padding: 10px;
  border-radius: 4px; /* Rounded corners for the caption */
}

@media (max-width: 768px) {
  .nav-bar {
    display: none; /* Hide the nav bar */
  }
  .app {
    margin-left: 0; /* Remove left margin on mobile */
    width: 100%; /* Ensure full width on mobile */
  }

  .footer {
    display: flex;
    padding-bottom: 13vh;
    justify-content: top;
  }

  .aboutme-content {
    padding-left: 0;
    width: 90vw;
  }
  .hero {
    height: 100vh;
  }
  .newsletter {
    height: 55vh;
    padding-bottom: 15vh;
  }
}
